// @mui icons
//import FacebookIcon from "@mui/icons-material/Facebook";
//import TwitterIcon from "@mui/icons-material/Twitter";
//import GitHubIcon from "@mui/icons-material/GitHub";
//import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Whatcom Weather",
    image: logoCT,
    route: "/",
  },
  socials: [],
  menus: [
    {
      name: "Website",
      items: [
        { name: "about us", href: "/about-us" },
        { name: "blog", href: "/blog" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "Fire Resources", href: "https://iradesign.io/" },
        { name: "General Weather Resources", href: "https://www.creative-tim.com/bits" },
        { name: "Stream/River Resources", href: "https://www.creative-tim.com/affiliates/new" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "https://www.creative-tim.com/contact-us" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} {" "}
      <MKTypography
        component="a"
        href="whatcomweather.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Whatcom Weather
      </MKTypography>
      .
    </MKTypography>
  ),
};
