/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui icons
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import TocIcon from '@mui/icons-material/Toc';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import InfoIcon from '@mui/icons-material/Info';

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import LiveWeather from "layouts/pages/landing-pages/liveweather";
import Forecast from "layouts/pages/landing-pages/forecast";

// Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";


const routes = [
  {
    name: "Live Weather",
    icon: <AcUnitIcon />,
    route: "/live",
    component: <LiveWeather />,

  },
  {
    name: "Quick Links",
    icon: <ElectricBoltIcon />,
    collapse: [
      {
        name: "Weather Forecasts",
        description: "Reliable weather forecast sources",
        route: "/forecast",
        component: <Forecast />,
      },
      {
        name: "Historical Weather",
        description: "See our colors, icons and typography",
        href: "https://www.creative-tim.com/learning-lab/react/colors/material-kit/",
      },
      {
        name: "Water Resources",
        description: "Resources for rivers, lakes, and streams",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
      },
    ],
  },
  {
    name: "All Weather Links",
    icon: <TocIcon />,
    collapse: [
      {
        name: "page sections",
        description: "See all sections",
        dropdown: true,
        collapse: [
          {
            name: "page headers",
            route: "/sections/page-sections/page-headers",
            component: <PageHeaders />,
          },
          {
            name: "features",
            route: "/sections/page-sections/features",
            component: <Features />,
          },
        ],
      },
    ],
  },
  {
    name: "Fire Links",
    icon: <LocalFireDepartmentIcon />,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Fire Conditions",
        collapse: [
          {
            name: "about us",
            route: "/about-us",
            component: <AboutUs />,
          },
          {
            name: "Suggest a resource",
            route: "/contact-us",
            component: <ContactUs />,
          },
          {
            name: "authors",
            route: "/authors",
            component: <Author />,
          },
        ],
      },
    ],
  },
  {
    name: "About",
    icon: <InfoIcon />,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "about us",
            route: "/about-us",
            component: <AboutUs />,
          },
          {
            name: "Suggest a resource",
            route: "/contact-us",
            component: <ContactUs />,
          },
          {
            name: "authors",
            route: "/authors",
            component: <Author />,
          },
        ],
      },
    ],
  },
];

export default routes;
