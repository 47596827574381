/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
//import Information from "pages/LandingPages/AboutUs/sections/Information";
//import Team from "pages/LandingPages/AboutUs/sections/Team";
//import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
//import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/rain-background.jpg";

function LiveWeather() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={""}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Real-time Weather Data
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              We maintain this page of local weather data.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h2" align="center" fontWeight="bold" mb={3}>
          Local Radar
        </MKTypography>
        <MKTypography variant="body1" align="center" mb={3}>
          National Weather Service
        </MKTypography>
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ overflow: "hidden" }}
        >
          <iframe
            title="National Weather Service radar"
            width="80%"
            height="600"
            src="https://radar.weather.gov/?settings=v1_eyJhZ2VuZGEiOnsiaWQiOm51bGwsImNlbnRlciI6Wy0xMjIuMDc5LDQ4LjY4Nl0sImxvY2F0aW9uIjpudWxsLCJ6b29tIjo5LjUyNjE5OTEzNTQ4ODQ2OX0sImFuaW1hdGluZyI6ZmFsc2UsImJhc2UiOiJzdGFuZGFyZCIsImFydGNjIjpmYWxzZSwiY291bnR5IjpmYWxzZSwiY3dhIjpmYWxzZSwicmZjIjpmYWxzZSwic3RhdGUiOmZhbHNlLCJtZW51Ijp0cnVlLCJzaG9ydEZ1c2VkT25seSI6ZmFsc2UsIm9wYWNpdHkiOnsiYWxlcnRzIjowLjgsImxvY2FsIjowLjYsImxvY2FsU3RhdGlvbnMiOjAuOCwibmF0aW9uYWwiOjAuNn19"
          />
        
        </MKBox>
      </Card>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}

      >

        <MKTypography variant="h2" align="center" fontWeight="bold" mb={3}>
          Local Forecast
        </MKTypography>

        <MKTypography variant="body1" align="center" mb={3}>
          National Weather Service
        </MKTypography>

        <MKBox

          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ overflow: "hidden" }}
        >
          <iframe
            title="National Weather Service forecast"
            width="80%"
            height="600"
            src="https://forecast.weather.gov/MapClick.php?textField1=48.77&textField2=-122.45"
          />
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default LiveWeather;
