/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 icons
import AddLinkIcon from '@mui/icons-material/AddLink';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
//import Information from "pages/LandingPages/AboutUs/sections/Information";
//import Team from "pages/LandingPages/AboutUs/sections/Team";
//import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
//import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/trees-background.jpg";
import MKButton from "components/MKButton";

function Forecast() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={""}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Forecasts
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Known accurate forecast sources for Whatcom County, WA.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Card 
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          variant="outlined"
        >
          <MKTypography variant="h1" align="center" fontWeight="bold" mb={3}>
            National Weather Service
          </MKTypography>

          <MKButton
            variant="gradient"
            color="info"
            href="https://www.weather.gov/"
            width="70%" 
          >
            <AddLinkIcon />
            National Weather Service
          </MKButton>

          <MKTypography variant="body1" align="center" fontWeight="bold" mb={3}>
            National Weather Service is your source for the most complete weather forecast and weather related information on the web.
          </MKTypography>
        </Card>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          variant="outlined"
        >
          <MKTypography variant="h1" align="center" fontWeight="bold" mb={3}>
            Weather Underground
          </MKTypography>

          <MKButton
            variant="gradient"
            color="info"
            href="https://www.wunderground.com/"
            width="70%" 
          >
            <AddLinkIcon />
            Weather Underground
          </MKButton>

          <MKTypography variant="body1" align="center" fontWeight="bold" mb={3}>
            Weather Underground provides local & long-range weather forecasts, weather reports, maps & tropical weather conditions for locations worldwide.
          </MKTypography>
        </Card>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          variant="outlined"

        >

          <MKTypography variant="h1" align="center" fontWeight="bold" mb={3}>
            Weather.com
          </MKTypography>

          <MKButton
            variant="gradient"
            color="info"
            href="https://weather.com/"
            width="70%" 
          >
            <AddLinkIcon />
            Weather.com
          </MKButton>

          <MKTypography variant="body1" align="center" fontWeight="bold" mb={3}>
            The Weather Channel and weather.com provide a national and local weather forecast for cities, as well as weather radar, report and hurricane coverage.
          </MKTypography>

        </Card>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          variant="outlined"
        >
            
            <MKTypography variant="h1" align="center" fontWeight="bold" mb={3}>
              AccuWeather
            </MKTypography>
  
            <MKButton
              variant="gradient"
              color="info"
              href="https://www.accuweather.com/"
              width="70%" 
            >
              <AddLinkIcon />
              AccuWeather
            </MKButton>
  
            <MKTypography variant="body1" align="center" fontWeight="bold" mb={3}>
              AccuWeather has local and international weather forecasts from the most accurate weather forecasting technology featuring up to the minute weather reports.
            </MKTypography>
          </Card>

      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Forecast;
